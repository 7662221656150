import dayjs from "dayjs"
import { jsPDF } from "jspdf";

import companyLogo from "branch/resource/company-logo.png"
import { numberFormat } from "utils";
//{jobOrderData, shipmentData, jobOrdIds, fName, msData, isPrintDateTime}
export const printJobOrder = ({printDataArr, msData}) => {

  console.log("printDataArr::", printDataArr)
  const companyData = msData.serverData.CompanyData

  const pageSize = [8.3, 11.7]; //A4
  const defaultHeaderSize = 20;
  const comapanyAddrSize = 11;
  const contentHeaderSize = 13;
  const contentSize = 18;

  const startY = 0.4
  const startX = 0.5
  const logoWidth = 1.6
  const logoHeight = 1.6
  const lineHeight = 0.25

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });
  doc.setFont("THSarabun")
  
  let i = 0;
  for(const {jobOrderData, shipmentData, jobOrdIds, isPrintDateTime, fName} of printDataArr){
    shipmentData.ShpmTypNm = msData.shipmentTypes.find(st=>st.ShpmTypId === shipmentData.ShpmTypId)?.ShpmTypNm || ""
    let j = 0;
    do {
      if (i + j !== 0) {
        doc.addPage(pageSize, "portrait")
      }
      let yPos = startY
      let xPos = startX
      let startCopy = startY
      for(const copy of [1,2]){
        if(copy === 2 ){
          startCopy = 5.85
          doc.setLineDashPattern([0.5, 3.2, 0.9, 3.2, 0.5])
          doc.line(0, startCopy, 8.3, startCopy)
          doc.setLineDashPattern()
          startCopy += 0.4
        }
        yPos = startCopy
        xPos = startX
        const img = new Image()
        img.src = companyLogo
    
        try {
          doc.addImage(img, 'png', xPos, yPos -0.3, logoWidth, logoHeight)
          console.log("imgWidth::", img.width)
          xPos += logoWidth +0.2
        } catch (e) {
          console.log("error::", e)
        }
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(defaultHeaderSize)
        yPos += 0.2
        doc.text(companyData.NameTH, xPos, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(comapanyAddrSize)
        yPos += lineHeight
        doc.text(companyData.AddrTH, xPos, yPos)
        yPos += lineHeight
        doc.text(`เลขประจำตัวผู้เสียภาษี: ${companyData.TaxId}`, xPos, yPos)
        yPos += lineHeight
        doc.text(`โทร: ${companyData.Tel}${companyData.Fax && ` Fax: ${companyData.Fax}`}`, xPos, yPos)
    
        yPos = startCopy +0.1
        xPos = 6.2
        const jobOrdTextX = 6.2 
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentSize + 5)
        doc.text("ใบปฏิบัติงาน", jobOrdTextX, yPos, { align: "left" })
    
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentSize - 2)
        doc.text(copy===1?"(ต้นฉบับ)":"(สำเนา)", jobOrdTextX + 1.15, yPos)
        const yPosTopRight = yPos + lineHeight + lineHeight *2/3
        yPos = yPosTopRight
        doc.text("เลขที่ใบงาน: ", xPos, yPos)
        yPos += lineHeight
        doc.text("ประเภทงาน: ", xPos, yPos)
        yPos += lineHeight
        doc.text("วันที่: ", xPos, yPos)
        yPos = yPosTopRight
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize + 5)
        xPos += 0.8
        doc.text(jobOrdIds[j].toString(), xPos, yPos)
        yPos += lineHeight
        doc.setFontSize(contentSize)
        doc.text(shipmentData.ShpmTypNm, xPos, yPos)
        yPos += lineHeight
    
        if(jobOrderData.AptTm){
          const aptTm = dayjs(jobOrderData.AptTm)
          let date = aptTm.format("DD/MM/YYYY")
          let time =  aptTm.format("HH:mm")
          if(isPrintDateTime){
            if(!isPrintDateTime.date){
              date = ""
            }
            if(!isPrintDateTime.time){
              time = ""
            }
          }
          if(time === "00:00") time = ""
          doc.text(`${date} ${time}`, xPos - 0.2, yPos)
        }
    
    
        const lineOffsetX = 0.05
        const lineOffsetY = 0.05
        const lineLenght = 2.5 - 0.7
        const lineLenght2x = 2.5 * 2 - 0.7
        const lineLenght3x = 2.5 * 3 - 0.7
        const yPosContent = yPos + 0.3
        const colHeader1 = startX
        const colText1 = colHeader1 + 0.7
        const lineText1 = colText1 - lineOffsetX
        const colHeader2 = colHeader1 + 2.5
        const colText2 = colHeader2 + 0.7
        const lineText2 = colText2 - lineOffsetX
        const colHeader3 = colHeader2 + 2.5
        const colText3 = colHeader3 + 0.7
        const lineText3 = colText3 - lineOffsetX
        const lineHeightContent = lineHeight + lineHeight / 2
    
        doc.setLineWidth(0.007)
    
        yPos = yPosContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("ลูกค้า", colHeader1, yPos)
        doc.text("JOB No.", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        const shortCusNms = doc.splitTextToSize(shipmentData.CusNm, 4.3)
        doc.text(shortCusNms[0], colText1, yPos)
        doc.text(shipmentData.JobNo, colText3, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght2x, yPos + lineOffsetY)
        doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)
    
        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("รับ ตู้/สินค้า", colHeader1, yPos)
        doc.text("ส่ง ตู้/สินค้า", colHeader2, yPos)
        doc.text("คืน ตู้/สินค้า", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(jobOrderData.TakePlc || "", colText1, yPos)
        doc.text(jobOrderData.Loc || "", colText2, yPos)
        doc.text(jobOrderData.RtnPlc || "", colText3, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText2, yPos + lineOffsetY, lineText2 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)
    
        let contSizeFull = ""
        if (["20", "2x20", "40", "45"].includes(jobOrderData.ContSize)) {
          const contSize = jobOrderData.ContSize === "20x2" ? "20" : jobOrderData.ContSize
          if (jobOrderData.ContHq === 1 && jobOrderData.ContTyp === "GP") {
            contSizeFull = `${contSize}HQ`
          } else {
            contSizeFull = `${contSize}${jobOrderData.ContTyp}${jobOrderData.ContHq === 1 ? "HQ" : ""}`
          }
        } else {
          contSizeFull = jobOrderData.ContSize
        }
        if(jobOrderData.ContSize === "20"){
          contSizeFull = `1x${contSizeFull}`
        }
        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("Booking", colHeader1, yPos)
        doc.text("Agent", colHeader2, yPos)
        doc.text("ขนาดตู้", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(shipmentData.Bkg || "", colText1, yPos)
        doc.text(shipmentData.Agnt || "", colText2, yPos)
        doc.text(contSizeFull, colText3, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText2, yPos + lineOffsetY, lineText2 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)
    
        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("หมายเลขตู้", colHeader1, yPos)
        doc.text("หมายเลขซีล", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(jobOrderData.ContNo, colText1, yPos)
        doc.text(jobOrderData.SealNo, colText2, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght2x, yPos + lineOffsetY)
        doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)
    
        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("วัน-เวลาถึงที่ส่ง", colHeader1, yPos)
        doc.text("วัน-เวลาออกที่ส่ง", colHeader2, yPos)
        doc.text("ปิดรับตู้", colHeader3, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(jobOrderData.RtnDteEn ? dayjs(jobOrderData.RtnDteEn).format("DD/MM/YYYY HH:mm") : "", colText3, yPos)
        doc.line(lineText1 + 0.2, yPos + lineOffsetY, lineText1 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText2 + 0.25, yPos + lineOffsetY, lineText2 + lineLenght, yPos + lineOffsetY)
        doc.line(lineText3, yPos + lineOffsetY, lineText3 + lineLenght, yPos + lineOffsetY)
    
        yPos += lineHeightContent
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("หมายเหตุ", colHeader1, yPos)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(`${jobOrderData.Rmk} ${jobOrderData.Rmk && jobOrderData.Ref1?", ": ""}${jobOrderData.Ref1}`, colText1, yPos)
        doc.line(lineText1, yPos + lineOffsetY, lineText1 + lineLenght3x, yPos + lineOffsetY)
    
        yPos += lineHeightContent
    
        doc.lines([[5.7, 0], [0, lineHeightContent * 3], [-5.7, 0], [0, lineHeightContent * 3 * -1]], startX - 0.1, yPos - 0.2, [1, 1], 'S', true)
        doc.rect(startX + 5.65, yPos - 0.2, 1.9, lineHeightContent * 3)
    
        
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("ข้อมูลใบเสร็จ", colHeader1, yPos)
        doc.text("เงินแนบใบงาน", colText3 + 0.6, yPos)
        yPos += lineHeightContent - 0.15
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("TAX ID : ", colHeader1, yPos)
        doc.text(jobOrderData.DXRmk || "", colText3 + 0.1, yPos + 0.05)
        // doc.text("บาท", colText3 + 0.6 + 0.9, yPos + 0.05)
        // doc.line(colText3 + 0.1 + 0.6, yPos + 0.05, colText3 + 0.1 + 0.6 + 0.75, yPos + 0.05)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(shipmentData.Job.RcptTaxId, colText1, yPos)
        yPos += lineHeightContent - 0.15
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("ชื่อใบเสร็จ : ", colHeader1, yPos)
        doc.text("จำนวนเงิน : ", colText3 + 0.1, yPos + 0.1)
        doc.text(`${jobOrderData.DXAmnt === ""?"":numberFormat(+jobOrderData.DXAmnt*-1)} `, colText3 + 0.6 + 0.85, yPos + 0.07, {align: "right"})
        // doc.text("ปลายทาง : ", colText3 + 0.1, yPos + 0.1)
        doc.text("บาท", colText3 + 0.6 + 0.9, yPos + 0.1)
        doc.line(colText3 + 0.1 + 0.6, yPos + 0.1, colText3 + 0.1 + 0.6 + 0.75, yPos + 0.1)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize)
        doc.text(shipmentData.Job.RcptNm, colText1, yPos)
        yPos += lineHeightContent - 0.15
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("ที่อยู่: ", colHeader1, yPos)
        doc.text("น้ำมัน : ", colText3 + 0.1, yPos + 0.15)
        doc.text("ลิตร", colText3 + 0.6 + 0.9, yPos + 0.15)
        doc.line(colText3 + 0.1 + 0.6, yPos + 0.15, colText3 + 0.1 + 0.6 + 0.75, yPos + 0.15)
        doc.setFont("THSarabun", "normal")
        doc.setFontSize(contentSize - 5)
        const addrs = doc.splitTextToSize(shipmentData.Job.RcptAddr, 5.2)
        doc.text(addrs.join("\n"), colText1 - 0.3, yPos)
    
        const drvSts = jobOrderData.DrvSts1 || jobOrderData.DrvSts2 || jobOrderData.DrvSts3 || jobOrderData.DrvSts4 || jobOrderData.DrvSts5 || ""
        const drvNm = drvSts?drvSts.split(";")[1]:""
        yPos += lineHeightContent * 2 + lineHeightContent / 4
        doc.setFont("THSarabun", "bold")
        doc.setFontSize(contentHeaderSize)
        doc.text("ผู้รับสินค้า (ตัวแทน)", colHeader1 + 0.5, yPos, { align: "center" })
        doc.text("ผู้จ่ายงาน", colHeader1 + 0.5 + 2, yPos, { align: "center" })
        doc.text(fName, colHeader1 + 0.5 + 2, yPos - 0.25, { align: "center" })
        doc.text("พนักงานขับรถ", colHeader1 + 0.5 + 4, yPos, { align: "center" })
        doc.setFontSize(contentSize + 5)
        doc.text(drvNm, colHeader1 + 0.5 + 4, yPos - 0.25, { align: "center" })
        doc.setFontSize(contentHeaderSize)
        doc.text("ทะเบียนรถ", colHeader1 + 0.5 + 6, yPos, { align: "center" })
        const lineEndY = yPos - 0.2
        const startLineEndX = colHeader1 - 0.2
        const lineEndLength = 1.5
        doc.line(startLineEndX, lineEndY, startLineEndX + lineEndLength, lineEndY)
        doc.line(startLineEndX + 2, lineEndY, startLineEndX + 2 + lineEndLength, lineEndY)
        doc.line(startLineEndX + 4, lineEndY, startLineEndX + 4 + lineEndLength, lineEndY)
        doc.line(startLineEndX + 6, lineEndY, startLineEndX + 6 + lineEndLength, lineEndY)
      }
      // doc.text(fName, colAccount, line11, { align: "center" })
      j++
      i++
    } while (j < jobOrdIds.length)
  }
  



  doc.output('dataurlnewwindow');
  // window.open(doc.autoPrint() , '_blank')
  // doc.autoPrint({variant: 'non-conform'});
  // window.open(doc.output('bloburl'), '_blank')
}