import { Route } from "react-router-dom/cjs/react-router-dom.min"
import JobPlaning from "./JobPlaning/JobPlaning"


// export const branchApiNm = {
//   getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
//   getTruckIncomeSummary: "/branch/getTruckIncomeSummary",
// }

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobPlaningV2: "การวางแผนงาน(V2)",
  JobOrderAllDetail: "รายละเอียดงานและคชจ.",
  TruckIncomeSummary: "รายงานรายได้แยกตามรถ",
  MapMT: "แผนที่",
  InvoiceReview: "ออกใบแจ้งหนี้มนตรี",
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
]

export const extraRoute = (path)=> [
  <Route key={"JobPlaning"} path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
]

export const extraMainRoute = (path)=> []
